import { WallsState } from '../@types/cell'
import { State } from '../@types/maze'

export default class Cell {
  x: number
  y: number
  visited: boolean
  walls: WallsState

  constructor (x: number, y: number) {
    this.x = x
    this.y = y
    this.visited = false
    this.walls = {
      top: true,
      right: true,
      bottom: true,
      left: true
    }
  }

  show ({ p, cellSize, currentCell, done }: State) {
    const x = this.x * cellSize
    const y = this.y * cellSize

    if (this.visited) {
      p.noStroke()
      p.fill(69, 90, 100)
      p.rect(x, y, cellSize, cellSize)
    }

    if (!done && currentCell.x === this.x && currentCell.y === this.y) {
      p.noStroke()
      p.fill(0, 200, 83)
      p.rect(x, y, cellSize, cellSize)
    }

    p.noStroke()

    if (this.visited) {
      p.stroke(229, 231, 235)
    }

    if (this.walls.top) {
      p.line(x, y, x + cellSize, y)
    }

    if (this.walls.right) {
      p.line(x + cellSize, y, x + cellSize, y + cellSize)
    }

    if (this.walls.bottom) {
      p.line(x, y + cellSize, x + cellSize, y + cellSize)
    }

    if (this.walls.left) {
      p.line(x, y, x, y + cellSize)
    }
  }

  checkNeighbours (state: State) {
    const neighbours = []

    const top = state.grid[Cell.getIndex(state, this.x, this.y - 1)]
    const right = state.grid[Cell.getIndex(state, this.x + 1, this.y)]
    const bottom = state.grid[Cell.getIndex(state, this.x, this.y + 1)]
    const left = state.grid[Cell.getIndex(state, this.x - 1, this.y)]

    if (top && !top.visited) {
      neighbours.push(top)
    }

    if (right && !right.visited) {
      neighbours.push(right)
    }

    if (bottom && !bottom.visited) {
      neighbours.push(bottom)
    }

    if (left && !left.visited) {
      neighbours.push(left)
    }

    if (neighbours.length > 0) {
      return neighbours[state.p.floor(state.p.random(0, neighbours.length))]
    }

    return null
  }

  removeWalls (state: State, target: Cell) {
    const x = this.x - target.x
    const y = this.y - target.y

    if (x === -1) {
      this.walls.right = false
      target.walls.left = false

      return
    }

    if (x === 1) {
      this.walls.left = false
      target.walls.right = false

      return
    }

    if (y === -1) {
      this.walls.bottom = false
      target.walls.top = false

      return
    }

    if (y === 1) {
      this.walls.top = false
      target.walls.bottom = false
    }
  }

  static getIndex ({ numCols, numRows }: State, x: number, y: number) {
    if (x < 0 || y < 0 || x > numCols - 1 || y > numRows - 1) {
      return -1
    }

    return x + y * numCols
  }
}
