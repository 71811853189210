/* eslint-disable no-unused-vars */
import { ChangeEvent, useState } from 'react'
import { throttle } from 'lodash'
import Maze from './Maze'

function App () {
  const [cellSize, setCellSize] = useState(20)
  const [showPath, setShowPath] = useState(false)
  const [showSolution, setShowSolution] = useState(false)
  const [targetFps, setTargetFps] = useState(60)

  const [actualFps, setActualFps] = useState(0)

  const updateTargetFps = (event: ChangeEvent<HTMLSelectElement>) => {
    setTargetFps(+event.target.value)
  }

  const updateCellSize = (event: ChangeEvent<HTMLSelectElement>) => {
    setCellSize(+event.target.value)
  }

  const updateShowPath = (event: ChangeEvent<HTMLInputElement>) => {
    setShowPath(event.target.checked)
  }

  const updateShowSolution = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSolution(event.target.checked)
  }

  const updateActualFps = throttle((fps: number) => {
    setActualFps(fps)
  }, 250)

  return (
    <div>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="h-8 w-8" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow" />
              </div>
              <div className="ml-10 flex items-baseline space-x-4">
                <a href="#" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Maze</a>

                {/* <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Team</a> */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Maze
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <Maze
              cellSize={cellSize}
              showPath={showPath}
              showSolution={showSolution}
              targetFps={targetFps}
              onActualFps={updateActualFps}
            />

            <div className="flex w-full space-x-4">
              <div className="w-full">
                {/* Target FPS */}
                <div className="mt-1 flex rounded-md shadow-sm" >
                  <label htmlFor="target-fps" className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    Target FPS
                  </label>
                  <select id="target-fps" value={targetFps} onChange={updateTargetFps} className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300">
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="60">60</option>
                    <option value="0">Over 9000!!!</option>
                  </select>
                </div>

                {/* Grid size */}
                <div className="mt-1 flex rounded-md shadow-sm" >
                  <label htmlFor="grid-size" className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    Grid size
                  </label>
                  <select id="grid-size" value={cellSize} onChange={updateCellSize} className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                </div>

                {/* Show path */}
                <div className="mt-1 flex">
                  <div className="flex items-center h-5">
                    <input id="show-path" type="checkbox" checked={showPath} onChange={updateShowPath} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="show-path" className="font-medium text-gray-700">Show current path?</label>
                    <p className="text-gray-500">Show a line following the current position of the generator</p>
                  </div>
                </div>

                {/* Show solution */}
                <div className="mt-1 flex">
                  <div className="flex items-center h-5">
                    <input id="show-solution" type="checkbox" checked={showSolution} onChange={updateShowSolution} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="show-solution" className="font-medium text-gray-700">Show solution?</label>
                    <p className="text-gray-500">Show the solution once it has been determined</p>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <p>
                  Actual FPS:
                  {' '}
                  <span className={targetFps > 0 && actualFps < targetFps ? 'text-yellow-600' : 'text-green-500'}>{actualFps}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default App
