import { useEffect, useRef } from 'react'
import P5 from 'p5'
import Cell from './objects/Cell'
import { State } from './@types/maze'

type Props = {
  cellSize: number
  showPath: boolean
  showSolution: boolean
  targetFps: number
  onActualFps: Function
}

export default function Maze ({ cellSize, showPath, showSolution, targetFps, onActualFps }: Props) {
  const ref = useRef<HTMLDivElement>(null)

  let p5: P5

  const sketch = (p: P5) => {
    let state: State

    const drawPath = (linePath: Cell[]) => {
      if (linePath.length < 2) {
        return
      }

      for (let i = 0; i < linePath.length; ++i) {
        if (linePath[i + 1]) {
          const a = linePath[i]
          const b = linePath[i + 1]

          p.line(
            (a.x * cellSize) + (cellSize / 2),
            (a.y * cellSize) + (cellSize / 2),
            (b.x * cellSize) + (cellSize / 2),
            (b.y * cellSize) + (cellSize / 2)
          )
        }
      }
    }

    p.setup = () => {
      p.createCanvas(ref.current?.clientWidth || 400, 400)

      p.frameRate(targetFps || 9001)

      const numCols = p.floor(p.width / cellSize)
      const numRows = p.floor(p.height / cellSize)
      const grid: Cell[] = []

      for (let y = 0; y < numRows; ++y) {
        for (let x = 0; x < numCols; ++x) {
          const cell = new Cell(x, y)

          grid.push(cell)
        }
      }

      const currentCell = grid[0]

      state = {
        p,
        cellSize,
        numCols,
        numRows,
        grid,
        path: [],
        solution: [],
        currentCell,
        done: false
      }
    }

    p.draw = () => {
      // stop looping if the maze is complete
      if (state.done) {
        p.noLoop()
      }

      onActualFps(state.done ? 0 : Math.round(p.frameRate()))

      // render each cell
      for (let i = 0; i < state.grid.length; ++i) {
        state.grid[i].show(state)
      }

      // show the current path
      if (showPath) {
        const linePath = state.path.slice()
        linePath.push(state.currentCell)
        p.stroke(0, 200, 83)
        drawPath(linePath)
      }

      // mark the current cell as visited
      state.currentCell.visited = true

      // find the next cell
      const next = state.currentCell.checkNeighbours(state)

      // move to the next cell, or go back if there isn't one
      if (next) {
        next.visited = true
        state.path.push(state.currentCell)
        state.currentCell.removeWalls(state, next)
        state.currentCell = next

        if (state.currentCell.x + 1 === state.numCols && state.currentCell.y + 1 === state.numRows) {
          state.solution = state.path.slice()
          state.solution.push(state.currentCell)
        }
      } else if (state.path.length > 0) {
        state.currentCell = state.path.pop() as Cell
      } else {
        state.done = true
      }

      // show the solution
      if (showSolution && state.solution.length > 0) {
        p.stroke(255, 109, 0)
        drawPath(state.solution)
      }

      // draw points
      p.noStroke()
      p.fill(0, 200, 83)
      p.ellipse(cellSize / 2, cellSize / 2, cellSize / 2, cellSize / 2)

      if (state.solution.length > 0) {
        p.fill(255, 109, 0)
        p.ellipse((state.numCols * cellSize) - (cellSize / 2), (state.numRows * cellSize) - (cellSize / 2), cellSize / 2, cellSize / 2)
      }
    }
  }

  useEffect(() => {
    p5 = new P5(sketch, ref.current as HTMLElement)

    return () => {
      p5.remove()
    }
  }, [cellSize, showPath, showSolution, targetFps])

  return (
    <div className="border-4 border-gray-200 bg-gray-100 rounded-lg" ref={ref}></div>
  )
}
